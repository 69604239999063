import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'universal-cookie'
import interceptors from '@/functions/request'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/overtime-request',
    name: 'transaction-overtime-requests',
    component: () => import('@/views/overtime-request/index.vue'),
  },
  {
    path: '/scheduler',
    name: 'transaction-scheduler',
    component: () => import('@/views/schedule/index.vue'),
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    component: () => import('@/views/account-settings/AccountSettings.vue'),
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/otp',
    name: 'otp',
    component: () => import('@/views/auth/Otp.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const cookies = new Cookies()
  const accessToken = cookies.get('access_token')

  if (to.path === '/auth/login' || to.path === '/auth/otp' || to.path === '/auth/forgot-password') {
    if (accessToken !== undefined) {
      interceptors()
      next({ path: '/dashboard', replace: true })
    } else {
      next()
    }
  } else if (accessToken === undefined) {
    next({ path: '/auth/login', replace: true })
  } else {
    interceptors()
    next()
  }
})

export default router
