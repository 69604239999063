import Cookies from 'universal-cookie/es6'
import fn from '../../functions/fn'

const states = {
  user: null,
}

const actions = {
  getUser({ commit }) {
    const cookie = new Cookies()
    let user = cookie.get('user')
    user = fn.base64_decode(user)
    commit('setUser', user)
  },
}

const mutations = {
  setUser(state, data) {
    state.user = data
  },
}

export default {
  states,
  actions,
  mutations,
}
