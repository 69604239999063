<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="imageSource"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px">
            <v-img :src="imageSource"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{$store.state.user.user.name}}
          </span>
          <small class="text--disabled">{{$store.state.user.user.username}}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item link :to="{name: 'account-settings'}">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Profile
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Logout -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title @click.prevent="logoutUser">Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Cookies from 'universal-cookie'
import axios from 'axios'
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { onBeforeMount, ref } from '@vue/composition-api'
import fnx from '@/functions/fn'

export default {
  setup(props, { root }) {
    const imageSource = ref(null)
    const defaultAvatar = require('@/assets/images/avatars/1.png')

    onBeforeMount(() => {
      const src = root.$store.state.user.user.image !== '' ? `${process.env.VUE_APP_URI}/image/${fnx.base64_encode(root.$store.state.user.user.image)}/payroll_users` : null
      imageSource.value = src !== null ? src : defaultAvatar
    })

    const logoutUser = async () => {
      const cookie = new Cookies()
      try {
        await axios.post(`${process.env.VUE_APP_URI}/api/auth/scheduler/logout`)
        cookie.remove('access_token', {
          path: '/',
          sameSite: true,
        })
        cookie.remove('user', {
          path: '/',
          sameSite: true,
        })
        window.location.href = '/'
      } catch (error) {
        //
      }
    }

    return {
      logoutUser,
      imageSource,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
