import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import '@/styles/custom.css'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import Vue from 'vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueMeta from 'vue-meta'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(PerfectScrollbar)
Vue.use(VueMeta)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
